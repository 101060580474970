import React, { useState, useEffect } from 'react'

export const App = () => {
    const [site, setSite] = useState(0);
    const [isMobile, setMobile] = useState(0);

    function handleWindowSizeChange() {
        if (window.innerWidth < 512){
            setMobile(0)
        } else if (window.innerWidth < 800) {
            setMobile(1)
        } else {
            setMobile(2)
        }
    }

    useEffect(() => {
        //Sets isMobile on resize
        handleWindowSizeChange();
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [])

    if (site == 0) {
        return (
            <div className='allDiv'>
                <div className={`boxDiv ${isMobile == 0 && 'mboxDiv'}`} style={{
                    width: isMobile == 0 ? "calc(100% - 50px)" : isMobile == 1 < 800 ? "70%" : "50%"
                }}>
                    <p className='title'>Rechtsanwälte Czich und Brießmann</p>
                    <p className={`switch ${isMobile == 0 && 'mswitch'}`}><i className="fa-solid fa-briefcase"></i> Tätigkeitsbereiche</p>
                    {!isMobile == 0 && <p className={`switch ${isMobile == 0 && 'mswitch'}`}><i className="fa-solid fa-paper-plane"></i> Kontakt</p>}
                    <div className={`cntDiv cntRight ${isMobile == 0 && 'mcntDiv'}`}>
                        <p>Verkehrszivilrecht (Unfallregulierung)</p>
                    </div>
                    {isMobile == 0 && <p className={`switch ${isMobile == 0 && 'mswitch'}`}><i className="fa-solid fa-paper-plane"></i> Kontakt</p>}
                    <div className={`cntDiv cntLeft ${isMobile == 0 && 'mcntDiv'}`}>
                        <p className='title2'>Büro Reichertshausen</p>
                        <p style={{ margin: 0 }}>Rechtsanwalt Czich</p>
                        <p className='address'>Peter-Rosegger-Str. 12<br />
                            85293 Reichertshausen<br />
                            Tel: 08441 / 8717023<br />
                            Tel: 0173 / 3832266<br />
                            E-Mail: <a className="blue" href="mailto:info@raeczich-briessmann.de">info@raeczich-briessmann.de</a>
                        </p>
                        <p className='title2'>Büro Weichs</p>
                        <p style={{ margin: 0 }}>Rechtsanwalt Brießmann</p>
                        <p className='address'>Pfarrer-Brachetti-Str. 25<br />
                            85258 Weichs<br />
                            Tel: 08136 / 893688<br />
                            Tel: 0174 / 5777909<br />
                            E-mail: <a className="blue" href="mailto:info@raeczich-briessmann.de">info@raeczich-briessmann.de</a>
                        </p>
                    </div>
                    <p className='footer'><a className="blue textalignCenter cursor" onClick={() => setSite(1)}>Impressum</a> - <a className="blue textalignCenter cursor" onClick={() => setSite(2)}>Datenschutz</a></p>
                </div>
            </div>
        )
    }
    if (site == 1) {
        return (
            <div className='allDiv'>
                <div className={`boxDiv ${isMobile == 0 && 'mboxDiv'}`} style={{
                    width: isMobile == 0 ? "calc(100% - 50px)" : isMobile == 1 < 800 ? "70%" : "50%"
                }}>
                    <p className='title'>Rechtsanwälte Czich und Brießmann</p>
                    <p className='blue textalignCenter' onClick={() => setSite(0)}>Zurück</p>
                    <p className='title2'>Impressum</p>
                    <p class="text">Dienstanbieter (§ 5 TMG) : Rechtsanwalt Stefan Czich, Rechtsanwalt Walter Brießmann</p>
                    <p class="text">Anschrift und Kontaktdaten (§ 5 Abs. 1 Nr. 1 TMG) :</p>
                    <ul class="addul">
                        <li class="addli">Rechtsanwälte Czich & Brießmann</li>
                        <li class="addli">Peter-Rosegger-Str. 12</li>
                        <li class="addli"><br /></li>
                        <li class="addli">Büro Weichs:</li>
                        <li class="addli">Pfarrer-Brachetti-Str. 25</li>
                        <li class="addli">85258 Weichs</li>
                    </ul>
                    <p class="text">Kontaktaufnahme: (§ 5 Abs. 1 Nr. 2 TMG)</p>
                    <ul class="addul">
                        <li class="addli">Büro Reichertshausen</li>
                        <li class="addli">Tel: 08441 / 8717023</li>
                        <li class="addli">Mobil: 0173 / 3832266</li>
                        <li class="addli"><br /></li>
                        <li class="addli">Büro Weichs</li>
                        <li class="addli">Tel: 08136 / 893688</li>
                        <li class="addli"><br /></li>
                        <li class="addli">E-mail: <a class="maillink" href="mailto:info@raeczich-briessmann.de">info@raeczich-briessmann.de</a></li>
                    </ul>
                    <p class="text">
                        Angaben zur Aufsichtsbehörde ( § 5 Abs. 1 Nr. 3 TMG) : Rechtsanwaltskammer München.<br />
                        Landwehrstr. 61, 80336 München<br />
                        Angaben zur Aufsichtsbehörde ( § 5 Abs. 1 Nr. 3 TMG) : <a href="https://www.rak-muenchen.de">www.rak-muenchen.de</a><br /><br />
                        Berufsbezeichnung (§ 5 Abs. 1 Nr. 5 TMG) : "Rechtsanwalt" der Bundesrepublik Deutschland<br />
                        Es gelten folgende berufsrechtlichen Regelungen:<br /><br />
                        Bundesrechtsanwaltsordnung (BRAO), Rechtsanwaltsergütungsgesetz (RVG)<br /><br />
                        Berufsordnung für Rechtsanwälte (BORA)<br />
                        Die Texte sind abrufbar unter <a href="https://www.brak.de">www.brak.de</a><br /><br />
                        Umsatzsteuer (3 % Abs. 1 Nr. 6 TMG) : 605 / 50132<br /><br />
                        Die Rechtsanwaltskanzlei Czich & Brießmann ist eine Gesellschaft bürgerlichen Rechts (GbR) bestehend aus den Rechtsanwälten.<br /><br />
                        Stefan Czich und Walter Brießmann. Als GbR wird sie nicht in einem Register geführt.<br />
                    </p>
                </div>
            </div>
        )
    }
    if (site == 2) {
        return (
            <div className='allDiv'>
                <div className={`boxDiv ${isMobile == 0 && 'mboxDiv'}`} style={{
                    width: isMobile == 0 ? "calc(100% - 50px)" : isMobile == 1 < 800 ? "70%" : "50%"
                }}>
                    <p className='title'>Rechtsanwälte Czich und Brießmann</p>
                    <p className='blue textalignCenter' onClick={() => setSite(0)}>Zurück</p>
                    <p className='title2'>Datenschutzerklärung</p>
                    <p class="text"><b>1. Namen und Kontaktdaten von der für die Verarbeitung Verantwortlichen</b></p>
                    <p class="text">Diese Datenschutzinformation gilt für die Datenverarbeitung durch:</p>
                    <ul class="addul">
                        <li class="addli">Rechtsanwälte Czich & Brießmann</li>
                        <li class="addli">Peter-Rosegger-Str. 12</li>
                        <li class="addli">85293 Reichertshausen</li>
                        <li class="addli"><br /></li>
                        <li class="addli">Büro Weichs</li>
                        <li class="addli">Pfarrer-Brachetti-Str. 25</li>
                        <li class="addli">85258 Weichs</li>
                        <li class="addli"><br /></li>
                        <li class="addli">Büro Reichertshausen</li>
                        <li class="addli">Tel: 08441 / 8717023</li>
                        <li class="addli">Mobil: 0173 / 3832266</li>
                        <li class="addli"><br /></li>
                        <li class="addli">E-mail: <a class="maillink" href="mailto:info@raeczich-briessmann.de">info@raeczich-briessmann.de</a></li>
                    </ul>
                    <p class="text"><b>2. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung</b></p>
                    <p class="text">Beim Aufrufen unserer Website www.raeczich-briessmann.de werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch Informationen an den Server meiner Website gesendet. Diese Informationen werden temporär in einem sog. Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:</p>
                    <ul class="addul">
                        <li class="addli">- IP-Adresse des anfragenden Rechners,</li>
                        <li class="addli">- Datum und Uhrzeit des Zugriffs,</li>
                        <li class="addli">- Name und URL der abgerufenen Datei,</li>
                        <li class="addli">- Website, von der aus der Zugriff erfolgt (Referrer-URL),</li>
                        <li class="addli">- verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers.</li>
                        <li class="addli"><br /></li>
                        <li class="addli">Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:</li>
                        <li class="addli"><br /></li>
                        <li class="addli">- Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,</li>
                        <li class="addli">- Gewährleistung einer komfortablen Nutzung unserer Website,</li>
                        <li class="addli">- Auswertung der Systemsicherheit und -stabilität</li>
                    </ul>
                    <p class="text">Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Mein berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwende ich die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.</p>
                    <p class="text"><b>3. Weitergabe von Daten</b></p>
                    <p class="text">Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:</p>
                    <ul class="addul">
                        <li class="addli">- Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben,</li>
                        <li class="addli">- die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben,</li>
                        <li class="addli">- für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht, sowie</li>
                        <li class="addli">- dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. B DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.</li>
                    </ul>
                    <p class="text"><b>4. Kontaktaufnahme</b></p>
                    <p class="text">Im Rahmen der Kontaktaufnahme mit uns (z.B. per E-Mail) werden personenbezogene Daten erhoben. Diese Daten werden ausschließlich zum Zweck der Beantwortung Ihres Anliegens bzw. für die Kontaktaufnahme und die damit verbundene technische Administration gespeichert und verwendet. Rechtsgrundlage für die Verarbeitung dieser Daten ist unser berechtigtes Interesse an der Beantwortung Ihres Anliegens gemäß Art. 6 Abs. 1 lit. f DSGVO. Zielt Ihre Kontaktierung auf den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO. Ihre Daten werden nach abschließender Bearbeitung Ihrer Anfrage gelöscht. Dies ist der Fall, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist und sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen.</p>
                    <p class="text"><b>5. Betroffenenrechte</b></p>
                    <p class="text">Sie haben das Recht:</p>
                    <ul class="addul">
                        <li class="addli">- gemäß Art. 15 DSGVO Auskunft über Ihre von mir verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei mir erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;</li>
                        <li class="addli">- gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei mir gespeicherten personenbezogenen Daten zu verlangen;</li>
                        <li class="addli">- gemäß Art. 17 DSGVO die Löschung Ihrer bei mir gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;</li>
                        <li class="addli">- gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und ich die Daten nicht mehr benötige, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;</li>
                        <li class="addli">- gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;</li>
                        <li class="addli">- gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber mir zu widerrufen. Dies hat zur Folge, dass ich die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen darf und</li>
                        <li class="addli">- gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Kanzleisitzes wenden.</li>
                    </ul>
                    <p class="text"><b>6. Widerspruchsrecht</b></p>
                    <p class="text">Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns umgesetzt wird. Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an info@raeczich-briessmann.de.</p>
                    <p class="text"><b>7. Dauer der Speicherung</b></p>
                    <p class="text">Die für die Mandatierung von mir erhobenen personenbezogenen Daten werden bis zum Ablauf der gesetzlichen Aufbewahrungspflicht für Anwälte (6 Jahre nach Ablauf des Kalenderjahres, in dem das Mandat beendet wurde) gespeichert und danach gelöscht, es sei denn, dass ich nach Artikel 6 Abs. 1 S. 1 lit. c DSGVO aufgrund von steuer- und handelsrechtlichen Aufbewahrungs- und Dokumentationspflichten (aus HGB, StGB oder AO) zu einer längeren Speicherung verpflichtet bin oder Sie in eine darüber hinausgehende Speicherung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO eingewilligt haben.</p>
                    <p class="text"><b>8. Aktualität und Änderung dieser Datenschutzerklärung</b></p>
                    <p class="text">Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Mai 2019. Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Website unter https://www.raeczich-briessmann.de/datenschutzerklaerung von Ihnen abgerufen und ausgedruckt werden.</p>

                </div>
            </div>
        )
    }

}
